import { graphql } from 'gatsby';
import 'normalize.css/normalize.css';
import { buildComponent } from 'utils';
import 'assets/css/overrides.css';

const DynamicPage = ({ data, pageContext: { buildContext } }) => {
  if (data.contentfulComponentTree) {
    return buildComponent(
      JSON.parse(data.contentfulComponentTree.componentTree),
      buildContext,
    );
  }
  /* eslint-disable no-console */
  console.log('DynamicPage data error!');
  console.log('DynamicPage data error: ', JSON.stringify(data));
  /* eslint-enable no-console */
  return false;
};

export const query = graphql`
  query PageTreeQuery($slug: String!) {
    contentfulComponentTree(slug: { eq: $slug }) {
      componentTree
    }
  }
`;

export default DynamicPage;
